//import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormatCurrency } from "../../hooks";
import { toast } from "react-toastify";
import { getErrorMessage } from "../../utils";
import { axios, getSdkURL } from "../../services";
import { useElementContext } from "../../contexts";

const DraftOrderHeader = ({
  orderInfo,
  orderFulfillment,
  orderPayments,
  setShowSummary,
  showSummary,
  orderEditable,
  getOrderInfo,
}) => {
  const navigate = useNavigate();
  const [formatCurrency] = useFormatCurrency({});
  const {
    CommonModule: { TradeOrderNotes, TradeOrderPayment, TradeOrderAddress },
  } = useElementContext();

  const placeTradeOrder = () => {
    axios({
      method: "POST",
      url: `${getSdkURL()}api/scope/placeTradeOrder`,
      data: {
        orderID: orderInfo?.orderID,
      },
    }).then((response) => {
      if (Object.keys(response?.data?.errors || {}).length) {
        toast.error(getErrorMessage(response?.data?.errors));
      } else if (response?.data?.error && response?.data?.error !== "") {
        toast.error(response.data.error);
      } else {
        toast.success("Order has been placed succesfully.");
        navigate("/my-account/overview");
      }
    });
  };

  return (
    <>
      <div className="d-flex justify-content-between my-4">
        {(!showSummary || !orderEditable) && (
          <button
            className="btn btn-light btn-rounded btn-outline-secondary"
            onClick={() => navigate("/my-account/draftorder")}
          >
            <i className="bi bi-chevron-left"></i> &nbsp; Back to My Account
          </button>
        )}

        {showSummary && orderEditable && (
          <button className="btn btn-light btn-rounded btn-outline-secondary" onClick={() => setShowSummary(false)}>
            <i className="bi bi-pencil linkit"></i> &nbsp; Edit Order
          </button>
        )}

        {!showSummary && (
          <button className="btn btn-primary btn-rounded" onClick={() => setShowSummary(true)}>
            View Order Summary
          </button>
        )}

        {showSummary && orderEditable && (
          <button className="btn btn-primary btn-rounded" onClick={() => placeTradeOrder()}>
            Place Order
          </button>
        )}
      </div>
      <div className="row d-flex justify-content-between my-4">
        <div className="col-md-6 mb-4">
          <div className="d-flex align-items-center mb-2">
            <h1 className="h5 mb-0 me-2 pt-0 font-weight-bold">{orderInfo?.orderName}</h1>{" "}
            <span className="badge badge-pill bg-warning">{orderInfo?.orderStatusType_typeName}</span>
          </div>
          <TradeOrderNotes orderNotes={orderInfo?.orderNotes} orderID={orderInfo?.orderID} showSummary={showSummary} />
          {orderFulfillment && (
            <div className="d-flex align-items-center mb-2">
              <TradeOrderAddress
                orderFulfillment={orderFulfillment}
                orderID={orderInfo?.orderID}
                showSummary={showSummary}
                getOrderInfo={getOrderInfo}
              />
            </div>
          )}
        </div>
        <div className="col-md-6 col-lg-4">
          <div className="card p-3 bg-light border-0">
            <table className="table table-borderless m-0 text-right table-sm">
              <thead></thead>
              <tbody>
                <tr>
                  <td>Account:</td>
                  <td className="font-weight-bold text-right">{orderInfo?.account_calculatedFullName}</td>
                </tr>
                <tr>
                  <TradeOrderPayment
                    showSummary={showSummary}
                    poNumber={orderPayments?.purchaseOrderNumber ?? ""}
                    orderID={orderInfo?.orderID ? orderInfo?.orderID : ""}
                  />
                </tr>
                <tr>
                  <td>Total Items:</td>
                  <td className="font-weight-bold">{orderInfo?.calculatedTotalItems}</td>
                </tr>
                <tr>
                  <td>Order Total (Ex GST):</td>
                  <td className="font-weight-bold">{formatCurrency(orderInfo?.calculatedTotal)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default DraftOrderHeader;
