import View from "./ProductDetailsPage.pageView";

import { useComponentModel } from "./ProductDetailsPage.pageService";
import { ProductContextProvider } from "../../contexts/ProductContext";
import { useElementContext, useServiceContext } from "../../contexts";
import { useEffect } from "react";

const ProductDetailPage = () => {
  const {
    PageModule: { DynamicPage },
    CommonModule: { PageContentRow },
  } = useElementContext();
  return (
    <DynamicPage>
      {({ renderDynamicContent }: any) => (
        <>
          <PageContentRow>
            <ProductContextProvider>
              <ProductDetailPageView />
            </ProductContextProvider>
          </PageContentRow>
          {renderDynamicContent()}
        </>
      )}
    </DynamicPage>
  );
};

const ProductDetailPageView = () => {
  const { componentData } = useComponentModel();

  const { LogService } = useServiceContext();
  useEffect(() => {
    new LogService().viewItem([componentData.product]);
  }, [LogService, componentData.product]);

  // Add config override for product detail here
  return <View {...componentData} />;
};

export default ProductDetailPage;
