import { useState, useRef, useMemo } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { useFormatCurrency } from "../../hooks";
import { toast } from "react-toastify";
import { getErrorMessage } from "../../utils";
import { getSdkURL, axios } from "../../services";
import { useServiceContext } from "../../contexts";

const QuickAddTab = ({ orderID, getOrderInfo }) => {
  const typeaheadRef = useRef(null);
  const [formatCurrency] = useFormatCurrency({});
  const [products, setProducts] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [orderItems, setorderItems] = useState({});
  const [isAddingProduct, setAddingProduct] = useState(false);
  const { ProductService } = useServiceContext();
  const productService = useMemo(() => new ProductService(), [ProductService]);

  const filterBy = () => true;

  const addOrderItems = () => {
    if (isAddingProduct) return;
    setAddingProduct(true);
    getOrderInfo({ makeRequest: false });
    var skuIDs = "";
    var quantities = "";
    Object.entries(orderItems).map(([key, value]) => {
      skuIDs = skuIDs === "" ? key : `${skuIDs},${key}`;
      quantities = quantities === "" ? value.qty : `${quantities},${value.qty}`;
      return "";
    });

    axios({
      method: "POST",
      url: `${getSdkURL()}api/scope/addOrderItems`,
      data: {
        orderID,
        skuIDs,
        quantities,
        returnJSONObjects: "cart",
      },
    })
      .then((response) => {
        if (response?.status === 200 && response?.data?.failureActions.length === 0) {
          setorderItems({});
          toast.success("Items added successfully");
          getOrderInfo();
        } else toast.error(getErrorMessage(response?.data?.failureActions));
      })
      .finally(() => {
        setAddingProduct(false);
      });
  };

  const predictiveSearch = (searchstring) => {
    const source = axios.CancelToken.source();
    setisLoading(true);

    productService.searchTypeahead(searchstring, "product", source).then(({ data }) => {
      if ((data?.items || []).length > 0) {
        setisLoading(false);
        setProducts(data.items);
      }
    });
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        addOrderItems();
      }}
    >
      <div className="d-flex justify-content-end p-2 my-2">
        <button className="btn btn-primary" type="submit" disabled={!Object.keys(orderItems).length}>
          {isAddingProduct ? (
            <span className="spinner-border spinner-border-sm p-2" role="status" aria-hidden="true"></span>
          ) : (
            "Add To Order"
          )}
        </button>
      </div>
      <div className="col-12 table-responsive">
        <table className="table w-100">
          <thead>
            <tr>
              <th></th>
              <th className="ps-1">Product Code</th>
              <th></th>
              <th>Quantity</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(orderItems).length > 0 ? (
              Object.values(orderItems).map((orderItem) => {
                return (
                  <tr key={orderItem.skuID} className="p-2 align-middle">
                    <td style={{ width: "20px" }}>
                      <i
                        className="bi bi-trash3 text-primary"
                        role="button"
                        onClick={() => {
                          let items = Object.keys(orderItems)
                            .filter((skuID) => skuID !== orderItem.skuID)
                            .reduce((obj, key) => {
                              return {
                                ...obj,
                                [key]: orderItems[key],
                              };
                            }, {});
                          setorderItems({ ...items });
                        }}
                      ></i>
                    </td>
                    <td className="p-1 pe-3" style={{ minWidth: "200px" }}>
                      {orderItem?.title || orderItem?.description}
                    </td>
                    <td className="p-1" style={{ minWidth: "100px" }}>
                      {formatCurrency(orderItem.salePrice)}
                    </td>
                    <td className="p-1" style={{ width: "100px" }}>
                      <input
                        min={1}
                        required
                        type="number"
                        className="form-control"
                        style={{ borderRadius: "30px" }}
                        name="qty"
                        value={orderItem.qty}
                        onChange={(e) => {
                          let items = { ...orderItems };
                          items[orderItem.skuID]["qty"] = e.target.value;
                          setorderItems({ ...items });
                        }}
                      />
                    </td>
                    <td className="p-1 font-weight-bold ps-3">{formatCurrency(orderItem.qty * orderItem.salePrice)}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="5" className="text-center">
                  Please search by sku to add products
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="col-md-8 col-lg-4">
        <AsyncTypeahead
          className="border m-0 quickAdd-typeahead"
          filterBy={filterBy}
          id="postalCode"
          isLoading={isLoading}
          labelKey={(option) => option?.metadata?.title || option?.metadata?.description}
          minLength={3}
          onSearch={predictiveSearch}
          options={products}
          placeholder="Search sku"
          ref={typeaheadRef}
          onChange={(selected) => {
            if (selected.length > 0) {
              let items = { ...orderItems };
              console.log("orderItems", orderItems);
              items[selected.at(0).metadata.skuID] = selected.at(0).metadata;
              selected.at(0).metadata.qty = 1;
              setorderItems({ ...items });
              setTimeout(() => typeaheadRef.current.clear(), 800);
            }
          }}
        />
      </div>
    </form>
  );
};

export default QuickAddTab;
