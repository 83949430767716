import { useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";
import { useDeepCompareEffect } from "react-use";
import { useMemo, useRef, useState } from "react";
import isEqual from "fast-deep-equal";
import { processQueryParameters } from "../../../utils";
import { axios } from "../../../services";
import { useServiceContext } from "../../../contexts";

const buildPath = (params) => {
  return queryString.stringify(params);
};

const useReconcile = ({ option, brand, attribute, category, priceRange, productType }) => {
  const loc = useLocation();
  let core = queryString.parse(loc.search, {
    arrayFormat: "separator",
    arrayFormatSeparator: ",",
  });
  let queryStringParams = queryString.parse(loc.search, {
    arrayFormat: "separator",
    arrayFormatSeparator: ",",
  });
  const evaluation = (props) => {
    const { qs, facetKey, filter } = props;
    if (qs[facetKey] && qs[facetKey].length) {
      let params = Array.isArray(qs[facetKey]) ? qs[facetKey] : [qs[facetKey]];
      const missingFilter = params
        .filter((optionToValidate) =>
          !filter.options.filter((opt) => opt["slug"] === optionToValidate).map((data) => data).length
            ? optionToValidate
            : false,
        )
        .filter((data) => data);
      if (missingFilter.length > 0) {
        qs[facetKey] = params.filter((param) => !missingFilter.includes(param));
        return qs;
      }
    }
    return qs;
  };

  if (category && category !== {}) {
    [category].forEach((filter) => {
      queryStringParams = evaluation({
        filter,
        qs: queryStringParams,
        facetKey: `facet_category`,
      });
    });
  } else {
    Object.keys(queryStringParams)
      .filter((paramKey) => paramKey === "facet_category")
      .forEach((keyToDelete) => {
        delete queryStringParams[keyToDelete];
      });
  }
  if (productType && productType !== {}) {
    [productType].forEach((filter) => {
      queryStringParams = evaluation({
        filter,
        qs: queryStringParams,
        facetKey: `facet_productType`,
      });
    });
  }
  if (brand && brand !== {})
    [brand].forEach((filter) => {
      queryStringParams = evaluation({
        filter,
        qs: queryStringParams,
        facetKey: `facet_brand`,
      });
    });

  return {
    shouldUpdate: JSON.stringify(queryStringParams) !== JSON.stringify(core),
    queryStringParams,
  };
};

const paramsIncludesForcedFilter = (searchConfig, params) => {
  if (searchConfig?.forcedFilterOptions?.length) {
    let resetFilters = false;
    Object.keys(params).forEach((param) => {
      const [, paramType] = param.split("_");

      // test for exact match brand_slug
      if (searchConfig.forcedFilterOptions.includes(param) && params[param]?.length) resetFilters = true;

      // test for for forced prefix like brand
      searchConfig.forcedFilterOptions.forEach((ffo) => {
        if (ffo.startsWith(paramType) && params[param]?.length) resetFilters = true;
      });
      return param;
    });
    return resetFilters;
  }
  return true;
};

const useListing = (preFilter, searchConfig) => {
  let [isFetching, setFetching] = useState(true);
  let [records, setRecords] = useState([]);
  let [searchMeta] = useState([]);
  let [total, setTotal] = useState(0);
  let [pageSize, setPageSize] = useState(12);
  let [totalPages, setTotalPages] = useState(1);
  let [potentialFilters, setPotentialFilters] = useState({});
  let [sortBy, setSortBy] = useState([]);
  let [error, setError] = useState({ isError: false, message: "" });
  const { ProductService } = useServiceContext();
  const productService = useMemo(() => new ProductService(), [ProductService]);

  const loc = useLocation();
  let initialData = searchConfig.filters;

  const navigate = useNavigate();
  let params = processQueryParameters(loc.search);
  params = { ...initialData, ...params, ...preFilter };

  const documentType = searchConfig.params.productsListingFlag ? "product" : "sku";

  const isInitSearchRef = useRef(true);
  useDeepCompareEffect(() => {
    if (isInitSearchRef.current) {
      isInitSearchRef.current = false;
      if (searchConfig.searchCustomise?.preFilters?.length) {
        const serachPreFilter = searchConfig.searchCustomise?.preFilters.reduce((acc, cur) => {
          if (acc[`facet_${cur.slug}`]) acc[`facet_${cur.slug}`].push(cur.value);
          else acc[`facet_${cur.slug}`] = [cur.value];
          return acc;
        }, {});
        if (!isEqual({ ...serachPreFilter, ...params }, params)) {
          navigate(
            {
              pathname: loc.pathname,
              search: buildPath({ ...serachPreFilter, ...params }),
            },
            { replace: true },
          );
          return;
        }
      }
    }
    let source = axios.CancelToken.source();
    setFetching(true);

    productService
      .search(params, documentType, source)
      .then((data) => {
        setRecords(data.products);
        setPotentialFilters(data.potentialFilters || {});
        setSortBy(data.sortBy || []);
        setTotal(data.resultCount);
        setTotalPages(Math.ceil(data.resultCount / data.pageSize));
        setError({ isError: false, message: "" });
      })
      .catch(() => {
        setRecords([]);
        setPotentialFilters({});
        setTotal(0);
        setPageSize(12);
        setTotalPages(1);
        setError({ isError: true, message: "Something was wrong" });
      })
      .finally(() => {
        setFetching(false);
      });

    return () => {
      source.cancel();
    };
  }, [params, documentType]);

  const { shouldUpdate, queryStringParams } = useReconcile({
    ...potentialFilters,
  });

  if (
    preFilter &&
    preFilter.hasOwnProperty("facet_productType") &&
    window.location.pathname.startsWith("/product-type/") &&
    window.location.pathname.replace("/product-type/", "") !== preFilter.facet_productType
  ) {
    preFilter.facet_productType = window.location.pathname.replace("/product-type/", "");
  }

  if (
    preFilter &&
    preFilter.hasOwnProperty("facet_productType") &&
    window.location.pathname.startsWith("/range/") &&
    window.location.pathname.replace("/range/", "") !== preFilter.facet_range
  ) {
    preFilter.facet_productType = window.location.pathname.replace("/range/", "");
  }

  if (
    preFilter &&
    preFilter.hasOwnProperty("facet_brand") &&
    window.location.pathname.startsWith("/brand/") &&
    window.location.pathname.replace("/brand/", "") !== preFilter.facet_brand
  ) {
    preFilter.facet_brand = window.location.pathname.replace("/brand/", "");
  }

  const setPage = (pageNumber) => {
    params["currentPage"] = pageNumber;
    navigate({
      pathname: loc.pathname,
      search: buildPath(params),
    });
  };
  const setKeyword = (keyword) => {
    params = {
      ...initialData,
      ...preFilter,
      orderBy: params.orderBy,
      keyword: keyword,
    };
    navigate({
      pathname: loc.pathname,
      search: buildPath(params),
    });
  };
  const setSort = (sort) => {
    params["sort"] = sort;
    params["currentPage"] = 1;
    navigate({
      pathname: loc.pathname,
      search: buildPath(params),
    });
  };

  const updateAttribute = (attribute) => {
    let attributeFilters = params[attribute.filterName]?.split(",").filter((data) => data) || [];
    if (attributeFilters.includes(attribute.name)) {
      attributeFilters = attributeFilters.filter((item) => item !== attribute.name);
    } else {
      attributeFilters.push(attribute.name);
    }

    params[attribute.filterName] = attributeFilters;
    params["currentPage"] = 1;

    if (!paramsIncludesForcedFilter(searchConfig, params)) {
      params = {
        ...initialData,
        ...preFilter,
        orderBy: params.orderBy,
        keyword: params.keyword,
      };
    }

    navigate({
      pathname: loc.pathname,
      search: buildPath(params),
    });
  };

  if (shouldUpdate && !isFetching) {
    const path = queryString.stringify(queryStringParams, {
      arrayFormat: "comma",
    });
    params = processQueryParameters(path);
    navigate(
      {
        pathname: loc.pathname,
        search: path,
      },
      { replace: true },
    );
  }

  return {
    records,
    pageSize,
    potentialFilters,
    isFetching,
    total,
    totalPages,
    error,
    sortBy,
    setSort,
    updateAttribute,
    setPage,
    setKeyword,
    params,
    searchMeta,
  };
};

export { useListing };
