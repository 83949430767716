import { useState, memo, useCallback, useMemo } from "react";
import { useCSVReader, useCSVDownloader } from "react-papaparse";
import { toast } from "react-toastify";
import { chunk, concat, isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { useFormatCurrency } from "../../hooks";
import { getErrorMessage } from "../../utils";
import { getSdkURL, axios } from "../../services";

const skusPerPage = 200;

const CSVSkuItem = memo(({ sku, idx, updateSelectSkuQuantity, handleSelectedSkuRemove }) => {
  const [formatCurrency] = useFormatCurrency({});
  return (
    <tr className="align-items-center pb-2 border-bottom">
      <td>{sku.lineNumber + 1}</td>
      <td>{sku.skuCode}</td>
      <td>{sku.skuData.salePrice ? formatCurrency(sku.skuData.salePrice) : "-"}</td>
      <td>
        <input
          type="number"
          value={sku.qty}
          max={sku.skuData.calculatedQATS}
          onChange={(e) => {
            updateSelectSkuQuantity(idx, e.target.value);
          }}
          className="form-control"
          name="qty"
        />
      </td>
      <td>{sku.skuData.salePrice ? formatCurrency(sku.skuData.salePrice * sku.qty) : "-"}</td>
      <td className="text-danger">{sku.error && sku.errorMessages.join(",")}</td>
      <td>
        <button
          className="btn"
          onClick={(e) => {
            if (window.confirm("Do you want to remove this item ?")) {
              handleSelectedSkuRemove(idx);
            }
          }}
        >
          <i className="bi bi-trash3 text-primary"></i>
        </button>
      </td>
    </tr>
  );
});

const CSVAddTab = ({ orderID, getOrderInfo }) => {
  const { t } = useTranslation();
  const { CSVReader } = useCSVReader();
  const { CSVDownloader } = useCSVDownloader();
  const [formatCurrency] = useFormatCurrency({});

  const [addingToCart, setAddingToCart] = useState(false);
  const [progress, setProgress] = useState({});

  const [skuData, setSkuData] = useState([]);

  const fetchAllSkus = useCallback(
    async (imported, totalPages, currentPage = 1) => {
      const currentChunk = chunk(imported, skusPerPage)[currentPage - 1];

      setProgress((prevState) => ({ ...prevState, currentPage }));
      return Promise.all(
        currentChunk.map((sku) =>
          fetch(
            `${process.env.REACT_APP_DELTA_STORE_URL}/public/ultracommerce/product/transform/skus/bySkuCode/${sku.skuCode}`,
          )
            .then(async (data) => {
              sku.error = false;
              sku.errorMessages = [];
              sku.skuData = await data.json();
              if (sku.skuData.calculatedQATS < sku.qty) {
                sku.error = true;
                sku.errorMessages.push(
                  t("frontend.draftOrder.csvAdd.maxQtyAvailable", { maxQATS: sku.skuData.calculatedQATS }),
                );
              }
              return sku;
            })
            .catch((e) => {
              sku.error = true;
              sku.errorMessages.push(t("frontend.draftOrder.csvAdd.skuCodeNotFound", { skuCode: sku.skuCode }));
              return sku;
            }),
        ),
      ).then(async (skus) => {
        if (currentPage < totalPages) {
          return concat(skus, await fetchAllSkus(imported, totalPages, currentPage + 1));
        }
        return skus;
      });
    },
    [t],
  );

  const renderCSVReader = () => {
    return (
      <CSVReader
        onUploadAccepted={async (results) => {
          setProgress({
            currentPage: 0,
            totalPages: 0,
            total: 0,
            complete: false,
          });
          const imported = results.data
            .map((row, lineNumber) => ({
              lineNumber,
              skuCode: row[0],
              skuData: {},
              qty: row[1],
              error: false,
              errorMessages: [],
            }))
            .filter((sku) => sku.skuCode !== "");
          // ignore empty rows

          const totalPages = chunk(imported, skusPerPage).length;
          setProgress({
            currentPage: 0,
            totalPages,
            total: imported.length,
            complete: false,
          });

          const allSkus = await fetchAllSkus(imported, totalPages);

          setProgress((prevState) => ({
            ...prevState,
            complete: true,
          }));
          setSkuData(allSkus);
        }}
      >
        {({ getRootProps, acceptedFile, getRemoveFileProps }) => (
          <>
            <div className="csvadd-csvreadercontainer p-3 mt-2 d-flex flex-wrap gap-2">
              <div className="csvadd-buttons-container">
                <button className="upload btn btn-outline-secondary" type="button" {...getRootProps()}>
                  {t("frontend.draftOrder.csvAdd.button.browse_file")}
                </button>
                <div className="filename">{acceptedFile && acceptedFile.name}</div>
                <button className="clear btn btn-outline-secondary" onClick={() => setSkuData([])}>
                  {t("frontend.draftOrder.csvAdd.button.clear")}
                </button>
              </div>
              <CSVDownloader
                filename="CSV Template"
                data={() => {
                  return [
                    ["skuCode1", "2"],
                    ["skuCode2", "3"],
                    ["skuCode3", "4"],
                    ["skuCode4", "5"],
                  ];
                }}
              >
                <button className="btn btn-outline-secondary">
                  <i className="bi bi-filetype-csv" style={{ marginRight: "0.5rem" }}></i>
                  {t("frontend.draftOrder.csvAdd.button.download")}
                </button>
              </CSVDownloader>
            </div>
            {progress.total > 0 && !progress.complete && (
              <div className="alert alert-info mt-2 mb-2">{t("frontend.draftOrder.csvAdd.progress", progress)}</div>
            )}
          </>
        )}
      </CSVReader>
    );
  };

  const updateSelectSkuQuantity = useCallback(
    (idx, qty) => {
      setSkuData((prevState) => {
        const newState = [...prevState];
        const sku = { ...prevState[idx], qty };
        const errorMessages = [];

        if (isEmpty(sku.skuData)) {
          errorMessages.push(t("frontend.draftOrder.csvAdd.skuCodeNotFound", { skuCode: sku.skuCode }));
        } else {
          if (sku.skuData.calculatedQATS < sku.qty) {
            sku.error = true;
            errorMessages.push(
              t("frontend.draftOrder.csvAdd.maxQtyAvailable", { maxQATS: sku.skuData.calculatedQATS }),
            );
          }
        }

        sku.error = errorMessages.length > 0;
        sku.errorMessages = errorMessages;
        newState[idx] = sku;
        return newState;
      });
    },
    [t],
  );

  const handleSelectedSkuRemove = useCallback((idx) => {
    setSkuData((prevState) => {
      const newState = [...prevState];
      newState.splice(idx, 1);
      return newState;
    });
  }, []);

  const addToCart = (skus) => {
    const skuIDs = [];
    const quantities = [];
    skus.forEach((sku) => {
      skuIDs.push(sku.skuData.skuID);
      quantities.push(sku.qty);
    });

    setAddingToCart(true);

    axios({
      method: "POST",
      url: `${getSdkURL()}api/scope/addOrderItems`,
      data: {
        orderID,
        skuIDs: skuIDs.join(","),
        quantities: quantities.join(","),
        returnJSONObjects: "cart",
      },
    }).then((response) => {
      setAddingToCart(false);
      if (response?.status === 200 && response?.data?.failureActions.length === 0) {
        toast.success("Items added successfully");
        getOrderInfo();
        setSkuData([]);
      } else toast.error(getErrorMessage(response?.data?.failureActions));
    });
  };

  const addAllToCart = () => addToCart(skuData.filter((sku) => !sku.error));
  const { successfulCount, errorCount, totalPrice } = useMemo(() => {
    return {
      successfulCount: skuData.filter((sku) => !sku.error).length,
      errorCount: skuData.filter((sku) => sku.error).length,
      totalPrice: formatCurrency(
        skuData
          .filter((sku) => !sku.error)
          .reduce((acc, sku) => {
            acc = acc + sku.qty * sku.skuData.listPrice;
            return acc;
          }, 0),
      ),
    };
  }, [formatCurrency, skuData]);

  return (
    <>
      {renderCSVReader()}
      {skuData.length > 0 && (
        <>
          <div className="csvAdd-completedProgress alert alert-secondary py-2 my-2 d-flex flex-wrap align-items-center justify-content-between">
            <div>{t("frontend.draftOrder.csvAdd.completedProgress", { successfulCount, errorCount, totalPrice })}</div>
            <button onClick={addAllToCart} className="btn btn-primary">
              <span className="d-inline-block me-2">
                {t("frontend.draftOrder.csvAdd.addToCart", { successfulCount })}
              </span>
              {addingToCart && (
                <div className="spinner-border spinner-border-sm ml-3" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
          </div>
          <div className="table-responsive">
            <table className="w-100">
              {skuData.length && (
                <thead>
                  <tr>
                    <th>CSV Line</th>
                    <th>Sku Code</th>
                    <th>Price</th>
                    <th>Quantity</th>
                    <th>Total</th>
                    <th>Error</th>
                    <th></th>
                  </tr>
                </thead>
              )}
              <tbody>
                {skuData.length &&
                  skuData.map((sku, idx) => (
                    <CSVSkuItem
                      key={sku.lineNumber}
                      sku={sku}
                      idx={idx}
                      handleSelectedSkuRemove={handleSelectedSkuRemove}
                      updateSelectSkuQuantity={updateSelectSkuQuantity}
                    />
                  ))}
              </tbody>
            </table>
          </div>
          {skuData.length > 20 && (
            <div className="alert alert-secondary mt-3 py-2 d-flex flex-wrap align-items-center justify-content-between">
              <span className="d-inline-block me-2">
                {t("frontend.draftOrder.csvAdd.addToCart", { successfulCount })}
              </span>
              <button onClick={addAllToCart} className="btn btn-primary">
                <span className="pr-3">{t("frontend.draftOrder.csvAdd.addToCart", { successfulCount })}</span>
                {addingToCart && (
                  <div className="spinner-border spinner-border-sm ml-3" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
              </button>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default CSVAddTab;
